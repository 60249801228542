<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-title>
        <slot
          v-if="$slots.title"
          name="title"
        ></slot>
        <menu-title
          v-else
          :title="title"
        ></menu-title>
      </v-card-title>
      <v-card-text>
        <slot
          v-if="$slots.text"
          name="text"
        ></slot>
        <span v-else>{{ text }}</span>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          @click="$emit('rejected');dialog=false"
        >
          {{ rejectText }}
        </v-btn>
        <v-spacer></v-spacer>
        <slot name="button"></slot>
        <v-btn
          outlined
          color="#b31616"
          @click="$emit('accepted');dialog=false"
        >
          {{ acceptText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import menuTitle from "./menu_title.vue";
export default {
  name: "acceptDialog",
  props: {
    value: Boolean,
    title: {
      type: String,
      required: false,
      default: "Continue?",
    },
    text: {
      type: String,
      required: false,
      default: "Are you sure you want to continue?",
    },
    acceptText: {
      type: String,
      required: false,
      default: "Yes",
    },
    rejectText: {
      type: String,
      required: false,
      default: "No",
    },
  },
  components: {
    menuTitle,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
</style>