const adjectives = require("./adjectives.json");
const verbs = require("./verbs.json");
const nouns = require("./nouns.json");

function getRandomAdjective() {
  return adjectives[Math.floor(Math.random() * adjectives.length)];
}

function getRandomNoun() {
  return nouns[Math.floor(Math.random() * nouns.length)];
}

function getRandomVerb() {
  return verbs[Math.floor(Math.random() * verbs.length)];
}

function getRandomSentence(delimiter = "-") {
  var str = "";
  str += getRandomAdjective() + delimiter;
  str += getRandomNoun() + delimiter;
  str += getRandomVerb() + delimiter;
  str += getRandomAdjective() + delimiter;
  str += getRandomNoun();
  return str;
}

const replacementTable = {
  a: ["4"],
  b: ["8"],
  c: [],
  d: [],
  e: ["3"],
  f: [],
  g: ["9"],
  h: [],
  i: ["1"],
  j: [],
  k: [],
  l: [],
  m: [],
  n: [],
  o: ["0"],
  p: [],
  q: ["9"],
  r: ["2"],
  s: ["5"],
  t: ["7"],
  u: [],
  v: [],
  w: [],
  x: [],
  y: [],
  z: ["2"],
};

String.prototype.replaceAt = function (index, replacement) {
  return (
    this.substring(0, index) +
    replacement +
    this.substring(index + replacement.length)
  );
};

function strengthenPassword(sentence, strength = 1) {
  var randomIndex = Math.floor(Math.random() * sentence.length);
  var char = sentence.charAt(randomIndex);
  if (Object.keys(replacementTable).includes(char)) {
    if (replacementTable[char].length >= 1) {
      var replacement =
        replacementTable[char][
          Math.floor(Math.random() * replacementTable[char].length)
        ];
      sentence = sentence.replaceAt(randomIndex, replacement);
      if (strength <= 1) return sentence;
      else return strengthenPassword(sentence, strength - 1);
    } else {
      return strengthenPassword(sentence, strength);
    }
  } else {
    return strengthenPassword(sentence, strength);
  }
}

function uppercaseCharacter(sentence, delimiter = "-") {
  var words = sentence.split(delimiter);
  var index = Math.floor(Math.random() * words.length);
  if (/\d/.test(words[index][0]))
    return uppercaseCharacter(sentence, delimiter);
  words[index] = words[index][0].toUpperCase() + words[index].substring(1);
  return words.join(delimiter);
}

function getPassword() {
  var sentence = getRandomSentence();
  sentence = strengthenPassword(sentence, 1);
  sentence = uppercaseCharacter(sentence);
  return sentence;
}

module.exports = {
  getPassword: getPassword,
};
