<template>
  <v-card>
    <v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="6" align="left" class="nopad">
            <v-container align="left" class="nopad">
              <span align="left" class="partner_title"> Portal Users </span>
            </v-container>
          </v-col>
          <v-col cols="4" align="right">
            <v-container align="right">
              <v-switch
                class="ma-1"
                label="Hide Partners and Customers"
                v-model="showAllUsers"
              ></v-switch>
            </v-container>
          </v-col>
          <v-col cols="2" align="right">
            <v-container align="right">
              <v-btn
                v-if="showAdminOptions"
                @click="
                  selectedUser = null;
                  showUserDialog = true;
                "
                class="ma-1"
              >
                New User
              </v-btn>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead>
          <tr>
            <th align="left">ID</th>
            <th align="left">Role</th>
            <th align="left">Name</th>
            <th align="left">Email / Login Name</th>
            <th align="left">Last Seen</th>
            <th align="left">Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in displayedUsers" :key="user.id">
            <td align="left">{{ user.id }}</td>
            <td align="left">{{ getUserRole(user) }}</td>
            <td align="left">{{ user.name }}</td>
            <td align="left">{{ user.email }}</td>
            <td align="left">
              {{ lastUserloginByUserid(user.id).timestampstring }}
            </td>
            <td align="left">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                    color="#b31616"
                    @click="
                      selectedUser = user;
                      showUserDialog = true;
                    "
                  >
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit User</span>
              </v-tooltip>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="isUserInUse(user)"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    color="#b31616"
                    @click="
                      selectedUser = user;
                      deleteDialog = true;
                    "
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete User</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>

    <!--     <accept-dialog
          v-model="deleteDialog"
          v-if="deleteDialog"
          title="Delete User?"
          text="Are you sure you want to delete this user?"
          @accepted="deleteAUser(selectedUser.id); confirmDelete = false"
        >
        </accept-dialog> -->

    <!-- <v-dialog max-width="800" v-model="confirmDelete" persistent>
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to delete this user?
        </v-card-title>
        <v-card-actions>
          <v-btn @click="confirmDelete = false" outlined>
            <span>Cancel</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              deleteAUser(selectedUser.id);
              confirmDelete = false;
            "
            color="#b31616"
            outlined
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <accept-dialog
      v-model="deleteDialog"
      v-if="deleteDialog"
      title="Delete User?"
      text="Are you sure you want to delete this user?"
      @accepted="deleteAUser(selectedUser.id)"
    >
    </accept-dialog>

    <v-dialog max-width="800" v-model="showUserDialog" persistent>
      <userDialog
        v-if="showUserDialog"
        :user="selectedUser"
        @done="
          selectedUser = null;
          showUserDialog = false;
        "
      >
      </userDialog>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import userDialog from "../../components/user_dialog.vue";
import acceptDialog from "../../components/helpers/generic_accept_dialog.vue";
import jwt_module from "../../helpers/jwt";
export default {
  name: "Users",
  computed: {
    ...mapGetters({
      users: "allUsers",
      privilegedUsers: "privilegedUsers",
      customers: "allCustomers",
      userloginsByUserid: "userloginsByUserid",
      lastUserloginByUserid: "lastUserloginByUserid",
    }),
  },
  components: {
    userDialog,
    acceptDialog,
  },
  data: () => ({
    deleteDialog: false,
    showUserDialog: false,
    showAllUsers: false,
    displayedUsers: [],
    showAdminOptions: true,
    selectedUser: {},
  }),
  methods: {
    ...mapActions({
      fetchUsersAsync: "fetchUsersAsync",
      fetchCustomersAsync: "fetchCustomersAsync",
      fetchUserloginsAsync: "fetchUserloginsAsync",
      deleteUser: "deleteUser",
      showSnackbar: "showSnackbar",
    }),
    deleteAUser(userId) {
      this.deleteUser(userId).then((error) => {
        if (error) {
          this.showSnackbar("Could not delete User: " + error);
        } else {
          this.showSnackbar("Successfuly deleted User");
        }
        this.deleteDialog = false;
      });
    },
    getUserRole: function (u) {
      if (u.is_admin) {
        return "Admin";
      } else if (u.is_manager) {
        return "Manager";
      } else if (u.is_partner) {
        return "Partner";
      } else if (u.is_customer) {
        return "Customer";
      }
    },
    isUserInUse(user) {
      return (
        jwt_module.decode(localStorage.getItem("jwt")).payload.id !== user.id
      );
    },
  },
  async mounted() {
    await this.fetchUsersAsync();
    await this.fetchCustomersAsync();
    await this.fetchUserloginsAsync();
    this.displayedUsers = this.users;
    console.log(this.displayedUsers);
    this.displayedUsers = this.displayedUsers
      .map((user) => {
        user["lastLogin"] = this.lastUserloginByUserid(user.id);
        console.log(user.lastLogin.timestamp);
        return user;
      })
      .sort((u1, u2) => u2.lastLogin.timestamp - u1.lastLogin.timestamp);
    console.log(this.displayedUsers.map((u) => u.lastLogin.timestamp));
    this.showAdminOptions =
      jwt_module.decode(localStorage.getItem("jwt")).payload.is_admin == true;
  },
  watch: {
    showAllUsers: function (newValue) {
      //this.displayedUsers = newValue ? this.users : this.privilegedUsers;
      if (newValue == false) {
        this.displayedUsers = this.users;
      } else {
        this.displayedUsers = this.privilegedUsers;
      }
    },
  },
};
</script>
<style scoped>
.nopad {
  padding-left: 0px !important;
}
.partner_title {
  font-size: 2.2rem;
  color: #b31616;
}
</style>