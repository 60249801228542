<template>
  <v-card>
    <v-card-title>
      <menu-title :title="user ? 'Edit User' : 'Create User'"></menu-title>
    </v-card-title>
    <v-card-text>
      <!-- First Row -->
      <v-row>
        <v-col cols="2">
          <v-text-field
            outlined
            v-model="tmpUser.id"
            label="ID"
            hide-details
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="10">
          <v-text-field
            outlined
            v-model="tmpUser.name"
            label="User name"
            hide-details
            :disabled="typeof user == undefined"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- Second Row -->
      <v-row>
        <v-col cols="8">
          <v-text-field
            outlined
            v-model="tmpUser.email"
            label="Email / Login name"
            hide-details
            :disabled="!editPermission"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-select
            outlined
            v-model="tmpUser.userGroup"
            :items="selectItems"
            :item-text="(item) => item.text"
            :item-value="(item) => item.value"
            hide-details
            :disbled="typeof user == undefined || !isadmin"
          ></v-select>
        </v-col>
      </v-row>
      <!-- Third Row -->
      <v-row>
        <v-col
          cols="3"
          v-if="user"
        >
          <v-checkbox
            label="Reset Password?"
            v-model="reset"
            :disabled="!editPermission"
          ></v-checkbox>
        </v-col>
        <v-col :cols="user ? 9 : 12">
          <v-text-field
            outlined
            v-model="tmpUser.password"
            append-icon="mdi-dice-6-outline"
            @click:append="randomizePassword"
            label="Password"
            hide-details
            :disabled="user && !reset"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        @click="$emit('done')"
        outlined
      >
        <span>Cancel</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="editPermission"
        @click="user ? saveUser() : addUser()"
        outlined
      >
        {{ user ? "Save" : "Add"}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import menuTitle from "../components/helpers/menu_title.vue";
import { mapActions, mapGetters } from "vuex";
import jwt_module from "../helpers/jwt";
export default {
  name: "userDialog",
  components: {
    menuTitle,
  },
  props: {
    user: Object,
  },
  data: () => ({
    tmpUser: {
      id: "",
      name: "",
      email: "",
      password: "",
      is_admin: false,
      is_manager: false,
    },

    isadmin: false,
    ismanager: false,

    reset: false,

    selectItems: [
      {
        text: "Admin",
        value: "admin",
      },
      {
        text: "Manager",
        value: "manager",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      users: "allUsers",
      customers: "allCustomers",
      customerById: "customerById",
    }),

    editPermission: function () {
      var currentUser = jwt_module.decode(localStorage.getItem("jwt")).payload;

      //console.log(currentUser);
      //console.log(this.user);
      if (currentUser.is_admin == true) {
        return true;
      }
      if (currentUser.is_manager == true) {
        if (this.user.id == currentUser.id) return true;
        if (this.user.is_admin || this.user.is_manager) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      postUser: "postUser",
      patchUser: "patchUser",
      showSnackbar: "showSnackbar",
    }),
    randomizePassword: function () {
      const pw = require("../helpers/passwordgenerator/passwordgenerator");
      var password = pw.getPassword();
      this.tmpUser.password = password;
    },
    saveUser: function () {
      this.tmpUser.password = this.tmpUser.password ?? null;
      this.patchUser(this.tmpUser).then((error) => {
        if (error) {
          this.showSnackbar(
            "User could not be updated. Error: {0}".format(error)
          );
        } else {
          this.showSnackbar("User Updated");
          this.$emit("done");
        }
      });
    },
    addUser: function () {
      this.tmpUser.is_admin = this.tmpUser.userGroup == "admin";
      this.tmpUser.is_manager = this.tmpUser.userGroup == "manager";
      this.postUser(this.tmpUser).then((error) => {
        if (error) {
          this.showSnackbar(
            "User could not be added. Error: {0}".format(error)
          );
        } else {
          this.showSnackbar("User added");
          this.$emit("done");
        }
      });
    },

    itxt: function (item) {
      return item.text;
    },
    ival: function (item) {
      return item.value;
    },
  },
  mounted() {
    if (this.user) {
      var newItems = [
        {
          text: "Partner",
          value: "partner",
        },
        {
          text: "Customer",
          value: "customer",
        },
      ];
      this.selectItems = this.selectItems.concat(newItems);
      this.tmpUser = JSON.parse(JSON.stringify(this.user));
      if (this.user.is_admin == true) this.tmpUser.userGroup = "admin";
      else if (this.user.is_manager == true) this.tmpUser.userGroup = "manager";
      else if (this.user.is_partner == true) this.tmpUser.userGroup = "partner";
      else if (this.user.is_customer == true)
        this.tmpUser.userGroup = "customer";

      // console.log("User:");
      // console.log(this.user);
    }
  },
};
</script>

<style>
</style>